<template>
  <v-content>
    <Clients/>
  </v-content>
</template>

<script>
  import Clients from '../components/Clients'

  export default {
    name: 'clients',
    components: {
      Clients
    }
  }
</script>
